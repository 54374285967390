<template>
  <div>
    <h2>Validate Pin</h2>
    <h3 class="mt-3"><b>IP Address:</b> {{ ipAddress }}</h3>
    <h3 class="mb-3"><b>Location:</b> {{ fullLocation }}</h3>
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" />
  </div>
</template>

<script>
import { UsersApi } from "@/services";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";

export default {
  components: { SubmitCancelRow },
  props: ["userId", "ipAddress"],
  data() {
    return {
      location: {}
    };
  },
  computed: {
    fullLocation() {
      const { city, subdivision, country } = this.location;
      if (!city && !subdivision && !country) {
        return "unknown.";
      }
      return `${city}, ${subdivision}, ${country}`;
    }
  },
  mounted() {
    this.getIpData();
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    async handleSubmit() {
      const response = await UsersApi.validateLogin(this.userId);
      if (response) {
        window.notify("PIN validated.");
        this.$emit("close");
      } else {
        window.alert("Error validating PIN.");
      }
    },
    async getIpData() {
      const data = await fetch(
        `https://www.iplocate.io/api/lookup/${this.ipAddress}/json?apikey=${process.env.VUE_APP_IPLOCATE_API_KEY}`,
        { method: "GET" }
      );
      const response = await data.json();
      this.location = response;
    }
  }
};
</script>

<style lang="scss" scoped></style>
