<template>
  <container class="p-1">
    <div class="d-flex justify-content-end mb-2">
      <div class="mr-2 d-inline-flex flex-column"></div>
    </div>
    <dx-grid-with-search
      title="User Management"
      gridName="users"
      @initialized="initializeGrid"
      :dataSource="dataSource"
      :columns="columns"
      :column-auto-width="true"
      :remote-operations="false"
      :toolbar="toolbar"
      :row-alternation-enabled="true"
    >
      <template v-slot:extraActions>
        <add-button v-show="permissions.UserCreateEdit" type="button" @click="handleAddUser" />
      </template>
      <template v-slot:actions-cell="{ data }">
        <div class="button-group d-flex">
          <icon-button
            v-if="permissions.UserAdminValidateMfaPin"
            class="m-auto text-success pointer p-0"
            :icon="'user-check'"
            @click="openValidatePin(data)"
            v-tooltip.left="'Validate PIN'"
          />
          <icon-button
            class="m-auto text-primary pointer p-0"
            :icon="'pen-alt'"
            @click="handleEditUser(data)"
            v-tooltip.left="'Edit user'"
          />
          <icon-button
            @click="removeUser(data)"
            v-tooltip.left="'Remove user from lab'"
            class="m-auto pointer p-0 text-danger"
            icon="ban"
          />
          <icon-button
            v-tooltip.right="'Reset password'"
            @click="handleUserForgotPassword(data)"
            class="m-auto text-warning pointer p-0"
            :icon="'bolt'"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isModalOpen" @close="toggleModal">
      <UserAdminEdit :userId="selectedUserId" @close="toggleModal" />
    </modal>
    <modal :status="isValidatePinOpen" @close="closeValidatePin">
      <ValidatePin
        :userId="userIdToValidatePin"
        :ipAddress="ipAddressToValidate"
        @close="closeValidatePin"
      />
    </modal>
  </container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UserService from "../services/users";
import DataSource from "devextreme/data/data_source";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import auditLog from "../services/AuditLog";
import { createLogItem, isSuperUser } from "../modules/helpers";
import Container from "@/components/common/Container.vue";
import Modal from "@/components/common/Modal.vue";
import UserAdminEdit from "@/views/UserAdminEdit.vue";
import IconButton from "@/components/common/IconButton.vue";
import AddButton from "@/components/common/AddButton.vue";
import AuditLog from "../services/AuditLog";
import { UsersApi } from "@/services";
import { handleErrors } from "@/modules/handleErrors";
import { UserTypesEnum } from "@/modules/enums";
import ValidatePin from "@/components/ValidatePin.vue";

export default {
  name: "UserAdmin",
  components: {
    DxGridWithSearch,
    Container,
    Modal,
    UserAdminEdit,
    IconButton,
    AddButton,
    ValidatePin
  },
  IconButton,
  metaInfo: {
    title: "User Management",
    titleTemplate: "IntelliPath - %s"
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Users page."
    });
  },
  data() {
    return {
      searchPanel: {
        visible: true,
        width: "300px"
      },
      isModalOpen: false,
      grid: {},
      availableLabs: [],
      scrolling: {
        showScrollbar: "always",
        useNative: true
      },
      selectedUserId: null,
      paging: {
        pageSize: 10,
        enabled: true
      },
      remoteOperations: {
        paging: true
      },
      userRoles: [],
      viewDisabled: false,
      isValidatePinOpen: false,
      userIdToValidatePin: null,
      ipAddressToValidate: null
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab", "permissions"]),
    ...mapGetters(["permissions"]),

    dataSource() {
      return new DataSource({
        store: UserService.labUsersStore,
        paginate: true,
        pageSize: 15,
        key: "id",
        cacheRawData: true,
        customQueryParams: {
          labId: this.currentLab
        },
        filter: this.dataSourceFilter
      });
    },
    columns() {
      return [
        {
          dataField: "firstName",
          caption: "Name",
          dataType: "string",
          calculateCellValue: this.calculateName,
          calculateFilterExpression: (value, operator) => {
            return operator
              ? [["firstName", operator, value], "or", ["lastName", operator, value]]
              : [["firstName", "contains", value], "or", ["lastName", "contains", value]];
          }
        },
        {
          dataField: "roles",
          caption: "Roles",
          allowSorting: true,
          allowFiltering: true,
          allowHeaderFiltering: false,
          dataType: "string",
          allowSearch: true,
          calculateCellValue: this.calculateDescription,
          calculateFilterExpression: filterValue => this.calculateRolesFilter(filterValue)
        },
        {
          type: "buttons",
          cellTemplate: "actions-cell",
          caption: "Actions",
          visible: this.permissions.UserCreateEdit
        }
      ];
    },
    toolbar() {
      return {
        items: [
          { location: "after", template: "extraActions" },
          {
            widget: "dxButton",
            visible: this.permissions.UserView,
            options: {
              icon: "fa fa-envelope",
              onClick: this.sendWelcomeEmails,
              hint: "Click to send welcome emails to selected users",
              elementAttr: {
                class: "icon-color"
              },
              text: "Send Welcome Emails"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.UserView,
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                "data-testId": "toolbar-action-excel",
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.currentUser.userTypeId === UserTypesEnum.SuperUser,
            options: {
              icon: "fa fa-book",
              onClick: this.goToDictionaryCopy,
              hint: "Click to go to Copy Dictionary screen",
              elementAttr: {
                "data-testId": "toolbar-action-dictionary",
                class: "icon-color"
              },
              text: "Copy Dictionary"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    },
    dataSourceFilter() {
      const disabledFilter = this.viewDisabled ? null : ["isEnabled", true];
      const superUserFilter =
        this.currentUser.userTypeId === UserTypesEnum.SuperUser
          ? null
          : ["!", ["userTypeId", UserTypesEnum.SuperUser]];
      if (disabledFilter && superUserFilter) {
        return [disabledFilter, "and", superUserFilter];
      } else {
        return superUserFilter || disabledFilter || null;
      }
    },
    isSuperUser() {
      return isSuperUser();
    }
  },
  methods: {
    initializeGrid(event) {
      this.grid = event.component;
    },
    toggleModal() {
      if (this.isModalOpen) {
        this.grid.refresh(true);
      }
      this.isModalOpen = !this.isModalOpen;
    },
    calculateName(data) {
      return data?.firstName + " " + data?.lastName;
    },
    calculateDescription(data) {
      return data?.roles.map(role => role.name).join(", ");
    },
    calculateRoleValue(data) {
      return data?.roles.map(role => role.name).toString();
    },
    handleAddUser() {
      this.selectedUserId = null;
      this.isModalOpen = true;
    },
    handleEditUser({ data }) {
      this.selectedUserId = data.id;
      this.isModalOpen = true;
    },
    async removeUser({ data }) {
      const confirm = await window.confirm(
        `Are you sure you want to remove ${data.displayName} (${data.userName}) from this lab?`
      );
      if (!confirm) {
        return;
      }
      await UserService.removeUserLabAssignment({
        userId: data.id,
        isEnabled: !data.isEnabled
      });
      data.isEnabled = !data.isEnabled;
      this.grid.refresh();
    },
    handleUserForgotPassword({ data }) {
      UserService.forgotPassword({ email: data.email }).then(() => {
        window.notify(`Email was sent to ${data.email}`);
      });
    },
    calculateRolesFilter(filterValue) {
      let filterArray = [];
      const inputArray = filterValue.replace(/[.,#!$%&;:{}=\-_`~()]/g, "").split(" ");
      for (let word in inputArray) {
        if (filterArray.length > 0) {
          filterArray.push("and");
        }
        filterArray.push(["roles", "contains", inputArray[word]]);
      }
      return filterArray;
    },
    exportToExcel() {
      let selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length) {
        this.grid.exportToExcel(true);
      } else {
        selectedRows = this.grid.getVisibleRows().map(e => e.data);
        this.grid.exportToExcel();
      }
      const logItem = createLogItem({}, 8);
      logItem.comments =
        "Exported the following users to excel: " +
        selectedRows.map(e => e.displayName).join(" \n ");
      AuditLog.insertLogMessage(logItem);
      return;
    },
    async sendWelcomeEmails() {
      const selectedRows = this.grid.getSelectedRowsData();
      if (!selectedRows.length) {
        window.alert("No users selected.");
      }
      const idArray = selectedRows.map(e => e.id);
      try {
        await UsersApi.sendWelcomeEmails(idArray);
        window.notify(`Sent ${idArray.length} welcome emails.`);
      } catch (error) {
        handleErrors(error);
      }
    },
    goToDictionaryCopy() {
      this.$router.push({ name: "CopyDictionary" });
    },
    async validateLogin({ data }) {
      // IP-1261: To be improved for production deployment.
      const success = await UsersApi.validateLogin(data.id);
      if (success) {
        window.notify("Validated PIN.");
      } else {
        window.alert("Error validating PIN.");
      }
    },
    openValidatePin({ data }) {
      UsersApi.getMfaCookie(data.id).then(res => {
        if (!res) {
          window.alert("No PIN was found to validate.");
        } else {
          this.isValidatePinOpen = true;
          this.userIdToValidatePin = data.id;
          this.ipAddressToValidate = res.ipAddress;
        }
      });
    },
    closeValidatePin() {
      this.isValidatePinOpen = false;
      this.userIdToValidatePin = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.layout-container {
  width: 100%;
}

.layout-content {
  display: flex;
  margin-top: 20px;
}

.user-admin-section {
  width: 100%;
  background: $white;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}
</style>
